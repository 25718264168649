<template>
  <v-container fluid>
    <!-- Título y descripción -->
    <v-form v-model="valid">
      <v-row class="px-5 pt-5 w-100" no-gutters>
        <div class="d-flex justify-between" style="width: 100%">
          <h2
            class="font-weight-bold"
            style="font-size: 24px; margin: 0; flex-grow: 1"
          >
            Existencia y precios
          </h2>
        </div>
        <p>Ingresa la información del producto</p>
        <v-radio-group v-model="product.batchesAndExpiration" class="ma-0 pa-0">
          <v-radio label="Utilizar lotes y caducidades" :value="true" />
          <v-radio
            v-if="false"
            label="No utilizar lotes y caducidades"
            :value="false"
          />
        </v-radio-group>
      </v-row>

      <!-- Formulario de Existencias -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <pat-input
                  type="number"
                  label="La Divina"
                  placeholder="0"
                  v-model="product.boxes"
                  :rules="[rules.required]"
                  class="mb-4"
                >
                  <template #append>
                    <span class="d-flex flex-row caption">cajas</span>
                  </template>
                </pat-input>
              </v-col>
              <v-col cols="4">
                <pat-input
                  type="number"
                  label="Reserva"
                  placeholder="0"
                  v-model="product.booking"
                  :rules="[rules.required]"
                  class="mb-4"
                />
              </v-col>
            </v-row>

            <p class="text-body-1">
              <span class="font-weight-bold">Ubicación </span>(Opcional)
            </p>
            <v-row>
              <v-col cols="4">
                <pat-input
                  label="No. Rack"
                  placeholder="Ej. B"
                  v-model="product.noRack"
                />
              </v-col>

              <v-col cols="4">
                <pat-input
                  label="Fila"
                  placeholder="Ej. 11"
                  v-model="product.row"
                />
              </v-col>

              <v-col cols="4">
                <pat-input
                  label="Columna"
                  placeholder="Ej. 4"
                  v-model="product.column"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Formulario de Precio de Compra -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold"
            >Precio de compra</v-card-title
          >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6" class="pa-0">
                <div class="d-flex align-center">
                  <span class="text-body-1 font-weight-medium ma-0 pa-0 mr-2"
                    >Costo</span
                  >
                  <v-select
                    v-model="product.costType"
                    :items="costOptions"
                    class="ml-0"
                    density="compact"
                    flat
                    :rules="[rules.required]"
                  ></v-select>
                </div>
              </v-col>

              <v-col cols="12" class="pa-0 mt-2">
                <pat-input
                  placeholder="0.00"
                  prefix="$"
                  v-model="product.costValue"
                  outlined
                  shaped
                  dense
                  hide-details
                  :rules="[rules.required]"
                >
                  <template #append>
                    <span class="d-flex flex-row caption">por caja</span>
                  </template>
                </pat-input>

                <p class="caption mt-1 black--text">Incluyendo impuestos</p>
              </v-col>
            </v-row>

            <p class="d-flex justify-space-between black--text">
              Costo unitario <span>${{ product.costValue }} por caja</span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Formulario de Precio de Venta -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold"
            >Precio de venta</v-card-title
          >
          <v-card-text>
            <div
              v-for="(price, index) in product.prices"
              :key="index"
              class="mb-5"
            >
              <div class="d-flex justify-space-between mb-2">
                <label class="d-flex align-center font-weight-bold mb-3">
                  Público
                </label>

                <v-btn
                  v-if="index > 0"
                  icon
                  color="red"
                  class="ml-3"
                  @click="removeVariant(index)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>

              <div class="d-flex align-center">
                <pat-input
                  prefix="$"
                  v-model="price.publicPrice"
                  placeholder="0.00"
                  :rules="[rules.required]"
                  @input="calculateProfit(index)"
                >
                  <template #append>
                    <span class="d-flex flex-row caption text-grey--text"
                      >por caja</span
                    >
                  </template>
                </pat-input>
              </div>

              <div class="d-flex justify-space-between black--text mt-2">
                <p>Margen: {{ price.marginPercentage }}%</p>
                <p>Ganancia: $ {{ price.profitAmount }} por caja</p>
              </div>
            </div>

            <v-col cols="12">
              <v-btn
                text
                color="primary"
                class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
                @click="addVariant"
              >
                <v-icon left color="primary">mdi-plus</v-icon>
                <span class="text-decoration-underline"
                  >Agregar otro precio</span
                >
              </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Botón de Guardar -->
      <v-card-actions class="justify-center">
        <v-btn
          :disabled="!valid"
          class="py-6"
          @click="submitForm"
          :color="valid ? 'primary' : 'grey darken-1'"
          block
          rounded
        >
          Guardar existencia
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      product: {
        batchesAndExpiration: null,
        boxes: "",
        booking: "",
        noRack: "",
        row: "",
        column: "",
        costType: "neto",
        costValue: "",
        prices: [{ publicPrice: "", marginPercentage: "", profitAmount: "" }],
        profitAmount: 0,
      },
      costOptions: ["neto", "bruto", "Opción 3"],
      // Aquí se corrige la regla para que retorne un booleano o string
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
        isNumber: (value) => {
          return (
            !value || /^-?\d+(\.\d+)?$/.test(value) || "Debe ser un número."
          );
        },
      },
    };
  },
  methods: {
    submitForm() {
      console.log("Form data:", this.product);
    },
    addVariant() {
      this.product.prices.push({
        publicPrice: "",
        marginPercentage: "",
        profitAmount: "",
      });
    },
    removeVariant(index) {
      if (index > 0) {
        this.product.prices.splice(index, 1);
      }
    },
    calculateProfit(index) {
      const price = this.product.prices[index];
      if (this.product.costValue > 0 && price.publicPrice > 0) {
        price.profitAmount = price.publicPrice - this.product.costValue;
        price.marginPercentage = (
          (price.profitAmount / price.publicPrice) *
          100
        ).toFixed(2);
      } else {
        price.marginPercentage = 0;
        price.profitAmount = 0;
      }
    },
  },
  watch: {
    "product.publicPrice": function () {
      this.calculateProfit();
    },
  },
};
</script>

<style scoped></style>
